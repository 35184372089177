







































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class Shop extends Mixins(Methods) {
	currentPage: number = 1
	pagesize: number = 10

	created(): void {
		this.getStore()
	}
	handleCurrentChange(currentPage: number): void{
        this.currentPage = currentPage
		this.storeList1 = this.storeList.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize)
    }
}
